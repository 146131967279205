import React, { useState, useEffect } from "react";
import Untitled1 from "./imagesM/Home.png";
import Untitled2 from "./imagesM/register.png";
import Untitled3 from "./imagesM/loginn.png";
import Untitled4 from "./imagesM/ReportButton.png";
import Untitled5 from "./imagesM/report.png";
import Untitled6 from "./imagesM/ReportMeLoginRegister.png";
import Untitled9 from "./imagesM/profile.png";
import Untitled10 from "./imagesM/chat.png";
import Loader from "../loader/loader";
import "./Manuals.scss";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
const Manuals = (propss) => {
    const [iSloading, setLoading] = useState(true);

    const translateMe = (key, langId) => {
        let diqka = propss?.langData?.filter((el) => el.key == key)[0];
        if (langId === "al") {
            return diqka?.content_al;
        }
        if (langId === "sr") {
            return diqka?.content_sr;
        }
        if (langId === "en") {
            return diqka?.content_en;
        }
    };
    useEffect(() => {
        setLoading();
    }, []);
    if (iSloading == true) {
        return <Loader />;
    }
    return (
        <div>
            <div className="container">
                <div className="manualet">
                    <div className="row">
                        <h2 className="text-center mt-4 mb-5 title">
                            {translateMe("stepsOfUsingTheApp", propss?.landId)}</h2>
                        <div className="col-sm-12 col-lg-6 ">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 1: </b>
                                <span>{translateMe("firstStep", propss?.landId)}</span>
                            </h5>
                            <img className="img-fluid mb-5 border border-primary" src={Untitled1} alt="#" />

                        </div>

                        <div className="col-sm-12 col-lg-6  ">
                            <h5 className="mb-3 "><b> {translateMe("step", propss?.landId)} 2: </b>
                                <span>{translateMe("secondStep", propss?.landId)}</span></h5>
                            {/* 
                        <h3>Hapi 2</h3>
                        <p>ju lutem perzgjedheni se me qfar ka te beje rasti qe doni te raportoni</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled2} alt="#" />

                        </div>
                        <div className="col-sm-12 col-lg-6 ">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 3: </b>
                                <span>{translateMe("thirdStep", propss?.landId)}</span></h5>
                            {/* 
                        <h3>hapi 3</h3>
                        <p>Ju lutem perzgjidhni qytetin ne te cilin keni pare rastin</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled3} alt="#" />

                        </div>
                        <div className="col-sm-12 col-lg-6 ">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 4: </b>
                                <span>{translateMe("fourthStep", propss?.landId)}</span></h5>
                            {/* 
                        <h3>Hapi 4</h3>
                        <p>ju lutem perzgjidhni citit komunitet i takon</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled4} alt="#" />

                        </div>
                        <div className="col-sm-12 col-lg-6 ">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 5: </b>
                                <span>{translateMe("fifthStep", propss?.landId)}</span></h5>
                            {/* <h3>Hapi 5</h3>
                        <p>Shkruani adresen e sakte se ku ka ndodhur rasti</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled5} alt="#" />

                        </div>
                        <div className="col-sm-12 col-lg-6 ">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 6: </b>
                                <span>{translateMe("sixthStep", propss?.landId)}</span></h5>
                            {/* <h3>Hapi 6</h3>
                        <p>Perxgjedhni se ne cilen agjensi doni ta dergoni kete raport</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled6} alt="#" />

                        </div>

                        <div className="col-sm-12 col-lg-6  mb-4">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 7: </b>
                                <span>{translateMe("seventhStep", propss?.landId)}</span></h5>
                            {/* <h3>Hapi i 9</h3>
                        <p>Shtypeni Gjinin</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled9} alt="#" />

                        </div>
                        <div className="col-sm-12 col-lg-6  mb-4">
                            <h5 className="mb-3"><b> {translateMe("step", propss?.landId)} 8: </b>
                                <span>{translateMe("eighthStep", propss?.landId)}</span></h5>
                            {/* <h3>Hapi i 10</h3>
                        <p>shkruani Moshen tuaj</p> */}
                            <img className="img-fluid mb-5 border border-primary" src={Untitled10} alt="#" />

                        </div>
                        {/* <div className="col-sm-12 col-lg-6 ">

                        <h1>Hapi 11</h1>
                        <p>Shkollimi juaj</p>
                        <img className="img-fluid mb-5 border border-primary" src={Untitled11} alt="#" />
                        
                        </div>
                        <div className="col-6">
                        
                        <h1>Hapi 12</h1>
                        <p>Employment status</p>
                        <img className="img-fluid" src={Untitled12} alt="#" />

                    </div>
                    <div className="col-6">
                    
                    <h1>Hapi 13</h1>
                    <p>Shkruani emailin tuaj</p>
                        <img className="img-fluid" src={Untitled13} alt="#" />
                        
                    </div>
                    <div className="col-6">

                    <h1>Hapi 14</h1>
                        <p>Ju lutem shtypni numrin tuaj</p>
                        <img className="img-fluid" src={Untitled14} alt="#" />
                        
                        </div>
                        <div className="col-6">
                        
                        <h1>Hapi 15</h1>
                        <p>Ngarkoni foton ose videon e rastit qe po raportoni</p>
                        <img className="img-fluid" src={Untitled15} alt="#" />

                    </div>
                    <div className="col-6">

                        <h1>Hapi 16 </h1>
                        <p>A mundemi ne te komntaktojm me ju per informacone me shume rreth rastit te raportuar nga ju</p>
                        <img className="img-fluid" src={Untitled16} alt="#" />

                        </div>
                        <div className="col-6">
                        
                        <h1>Hapi 17</h1>
                        <p>qe te dergoni raportin tuaj ju lutem shtypeni butonin Vazhdo</p>
                        <img className="img-fluid" src={Untitled17} alt="#" />
                        
                        </div>
                    <div className="col-6">
                    <h1>Regjistrohuni</h1>
                        <p>Nese doni te regjistroheni ju lutem klikoni ketu </p>
                        <img className="img-fluid" src={reg} alt="#" />

                        </div>
                        <div className="col-6">
                        <h1>Procesi Regjistrimit</h1>
                        <p>Nese doni te regjistroheni ju lutem jepini informacione tuaja pewrsolane siq jan : Emri  dhe emaili juaj dhe klikoni butonin Submit per ta krijuar</p>
                        <img className="img-fluid" src={reg2} alt="#" />
                        
                    </div>
                    <div className="col-6">
                    <h1>Kyquni</h1>
                    <p>Nese doni te kyqeni klikoni ketu</p>
                    <img className="img-fluid" src={login} alt="#" />

                    </div>
                    <div className="col-6 pb-4" >
                        <h1>Procesi per tu Kyqur</h1>
                        <p>Nese doni te kyqeni shkruani emrin dhe fjalkalimin tuaj te cilat i keni caktuar ne butonin Regjistrohu</p>
                        <img className="img-fluid" src={loginP} alt="#" />

                    </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        // loggedIn: state.data.loggedIn,
        // reportData: state.data.reportData,
        landId: state.data.langId,
        langData: state.data.langData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // logMeIn: () => dispatch(logMeIn()),
        // setReportData: (data) => dispatch(setReportData(data)),
        // Clear: () => dispatch(Clear()),
        // setLangID: (id) => dispatch(setLangID(id)),
        // setLangData: (data) => dispatch(setLangData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Manuals);