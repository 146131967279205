// import { arrowFunctionExpression } from "@babel/types";
import { CLEAR_DATA, LOGMEIN, SET_AUTH, SET_REPORT_DATA, LANG_DATA, LANG_ID} from "./types";

const INITIAL_STATE = {
    loggedIn: false,
    auth: null,
    reportData: null,
    langId: 'al',
    langData: null
};

const antigReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGMEIN:
            return {
                    ...state, loggedIn: true,
                };
        case SET_AUTH: 
            return { ...state, auth: action.auth};
        case SET_REPORT_DATA:
            return { ...state, reportData: action.data};
        case LANG_ID:
            return {...state, langId: action.langId};
        case LANG_DATA:
            return {...state, langData: action.data};
        case CLEAR_DATA:
            return INITIAL_STATE
            
        default: return state;
        
    }
};

export default antigReducer;