import { CLEAR_DATA, LOGMEIN, SET_AUTH,SET_REPORT_DATA , LANG_DATA, LANG_ID} from "./types";

export const logMeIn = () => {
    return { 
        type: LOGMEIN,
    };
};

export const setAuth = (auth) => {
    return {
        type: SET_AUTH,
        auth: auth
    };
};

export const setReportData = (data) => {
    return {
        type: SET_REPORT_DATA,
        data: data
    }
}
export const Clear = () => {
    return {
        type: CLEAR_DATA,
       
    }
}

export const setLangID = (id) => {
    return {
        type: LANG_ID,
        langId: id
    }
}
export const setLangData = (data) => {
    return {
        type: LANG_DATA,
        data: data
    }
}

