import React, { useEffect, useState, Link } from "react";
import "./Constitution.scss";
import Loader from "../loader/loader";
import Pdf_shqip from './pdf/Strategjia_shqip.pdf';
import Pdf_eng from './pdf/Strategy_eng.pdf';
import Pdf_srb from './pdf/Strategija_serbe.pdf';
import Pdf_rome from './pdf/Startegia_rome.pdf';


const Constitution = () => {
  const [iSloading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, []);
  return (
    <>
      {iSloading == true ? (
        // <div>Loading</div>
        <Loader />
      ) : (
        <div className="container">
          <div className="row">
            <h3 className="mb-5 mt-5  border-bottom  border-5 text-center">Kushtetuta </h3>
            <div className="col-md-4 mb-5 mt-5 ">
              <div className="card     card-border ">
                <div className="card-body p-4 bg-white w-100">
                  <h5 className="card-title fs-4  pb-4">Ligji Shqip</h5>
                  <p className="card-text">
                    <p className="mt-4 h-50">
                      <span>Lloji i aktit: Kushtetuta</span> <br />
                      <span>Numri i aktit: K-09042008</span> <br />
                      <span>Institucioni: Kuvendi i Republikës së Kosovës</span>
                    </p>
                  </p>
                  <a
                    href="https://gzk.rks-gov.net/ActDetail.aspx?ActID=3702&fbclid=IwAR1rbF3KocmzFefy1ndQGdXYwY0u-OQT9EvxE03GY_VZ0GlpaTUwsYrVWyk"
                    target="_blank"
                    className="text-left mt-5"
                  >
                    Lexo Kushtetuten...
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5 mt-5 ">
              <div className="card     card-border ">
                <div className="card-body p-4 bg-white w-100">
                  <h5 className="card-title fs-4  pb-4">Laws English</h5>
                  <p className="card-text">
                    <p className="mt-4 h-50">
                      <span>Type of act: Constitution</span> <br />
                      <span>Act number: K-09042008</span> <br />
                      <span>Institution: Assembly of the Republic of Kosovo</span>
                    </p>
                  </p>
                  <a
                    href="https://gzk.rks-gov.net/ActDetail.aspx?ActID=3702&fbclid=IwAR1rbF3KocmzFefy1ndQGdXYwY0u-OQT9EvxE03GY_VZ0GlpaTUwsYrVWyk"
                    target="_blank"
                    className="text-left mt-5"
                  >
                    Read the Constitution...{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5 mt-5 ">
              <div className="card     card-border ">
                <div className="card-body p-4 bg-white w-100">
                  <h5 className="card-title fs-4  pb-4">Ustav Serbska</h5>
                  <p className="card-text">
                    <p className="mt-4 h-50">
                      <span>Tip akta: Ustav</span> <br />
                      <span>Akt broj: K-09042008</span> <br />
                      <span>Institucija: Skupština Republike Kosovo</span>
                    </p>{" "}
                  </p>
                  <a
                    href="https://gzk.rks-gov.net/ActDetail.aspx?ActID=3702&fbclid=IwAR1rbF3KocmzFefy1ndQGdXYwY0u-OQT9EvxE03GY_VZ0GlpaTUwsYrVWyk"
                    target="_blank"
                    className="text-left mt-5"
                  >
                    Pročitajte Ustav...{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="service ">

            <h3 className="mb-5 mt-5  border-bottom  border-5 text-center">
              Strategjitë{" "}
            </h3>
            <div className="row">
              <div className="col-md-3 mb-5 mt-5 ">
                <div className="card     card-border ">
                  <div className="card-body p-4 bg-white w-100">
                    <h4 className="card-title fs-4  pb-4">Strategjia</h4>
                    <h6 className="card-text">
                      STRATEGJIA PËR
                      AVANCIMIN E TË
                      DREJTAVE TË
                      KOMUNITETEVE ROM
                      DHE ASHKALI
                      NË REPUBLIKËN E
                      KOSOVËS 2022-2026
                      DHE PLANI I VEPRIMIT
                      2022-2024
                    </h6>
                    <a href={Pdf_shqip} target="_blank" className="text-left mt-5">
                      Lexo me shume...
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-5 mt-5 ">
                <div className="card     card-border ">
                  <div className="card-body p-4 bg-white w-100">
                    <h4 className="card-title fs-4  pb-4">Strategy</h4>
                    <h6 className="card-text text-capitalize">
                      STRATEGY FOR THE
                      ADVANCEMENT OF THE
                      RIGHTS OF THE
                      ROMA AND ASHKALI
                      COMMUNITIES
                      IN THE REPUBLIC OF
                      KOSOVO 2022-2026
                      AND THE ACTION PLAN
                      2022-2024
                    </h6>

                    <a href={Pdf_eng} target="_blank" className="text-left mt-5">
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-5 mt-5 ">
                <div className="card     card-border ">
                  <div className="card-body p-4 bg-white ">
                    <h4 className="card-title fs-4  pb-4">Strategija</h4>
                    <h6 className="card-text pb-2">
                      STRATEGIJA ZA UNAPREĐENJE PRAVA ZAJEDNICA ROMA I AŠKALIJA U REPUBLICI KOSOVO2022-2026. I AKCIJSKI PLAN 2022-2024
                    </h6>

                    <a href={Pdf_srb} target="_blank" className="text-left mt-5">
                      Pročitajte Ustav...
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-5 mt-5 ">
                <div className="card     card-border ">
                  <div className="card-body p-4 bg-white ">
                    <h4 className="card-title fs-4  pb-4">Strategia</h4>
                    <h6 className="card-text pb-2">
                      STRATEGIA VASH
                      AVANSIPE E
                      HAKAYENGO E ROMANE
                      THAY ASHKALYUNE
                      KOMUNITETENGO ANI
                      KOSOVAKI REPUBLIKA
                      2022-2026
                      THAY AKCIAKO PLANI
                      2022-2024
                    </h6>

                    <a href={Pdf_rome} target="_blank" className="text-left mt-5">
                      Read more...
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      )}
    </>
  );
};

export default Constitution;

