import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./Events.scss";
import { Link } from "react-router-dom";
import axios from "../../axios";
import Loader from "../loader/loader";
import "../../App.css";
import { connect } from "react-redux";

const MoreEvents = (propss) => {
  const [events, setEvents] = useState([]);
  const [iSloading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/general/events/all")
      .then((res) => {
        setEvents(res?.data);
        setLoading();
        // setTimeout(() => setLoading(false), 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const casesPerPage = 3;
  const pagesVisited = pageNumber * casesPerPage;
  if (iSloading == true) {
    return <Loader />;
  }
  const displayCases = events
    .slice(pagesVisited, pagesVisited + casesPerPage)

    .map((el, key) => {
      if (propss?.landId == "al") {
        return (

          <>
            <div className="col-lg-4 col-md-6 col-12 bg-img pb-5 mb-5">
              <div
                className="text-lower shadow"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.50)), url("${`${process.env.REACT_APP_UPLOADS}` + el.pic_url
                    }`,
                }}
              >
                <p className="date">
                  {" "}
                  <i className="fa fa-calendar"></i> {el.from_date.slice(0, 10)}
                </p>
                <Link to={{ pathname: "/event/" + el.id }}>
                  <h5>{el.name_al}</h5>
                </Link>
                <p
                  className="event-desc"
                  dangerouslySetInnerHTML={{
                    __html: el.desc_al.slice(0, 30) + "...",
                  }}
                ></p>
              </div>
            </div>
          </>
        );
      }
      if (propss?.landId == "en") {
        return (
          <>
            <div className="col-lg-4 col-md-6 col-12 bg-img pb-5 mb-5">
              <div
                className="text-lower shadow"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.50)), url("${`${process.env.REACT_APP_UPLOADS}` + el.pic_url
                    }`,
                }}
              >
                <p className="date">
                  {" "}
                  <i className="fa fa-calendar"></i> {el.from_date.slice(0, 10)}
                </p>
                <Link to={{ pathname: "/event/" + el.id }}>
                  <h5>{el.name_en}</h5>
                </Link>
                <p
                  className="event-desc"
                  dangerouslySetInnerHTML={{
                    __html: el.desc_en.slice(0, 30) + "...",
                  }}
                ></p>
              </div>
            </div>
          </>
        );
      }
      if (propss?.landId == "sr") {
        return (
          <>
            <div className="col-lg-4 col-md-6 col-12 bg-img pb-5 mb-5">
              <div
                className="text-lower shadow"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.50)), url("${`${process.env.REACT_APP_UPLOADS}` + el.pic_url
                    }`,
                }}
              >
                <p className="date">
                  {" "}
                  <i className="fa fa-calendar"></i> {el.from_date.slice(0, 10)}
                </p>
                <Link to={{ pathname: "/event/" + el.id }}>
                  <h5>{el.name_sr}</h5>
                </Link>
                <p
                  className="event-desc"
                  dangerouslySetInnerHTML={{
                    __html: el.desc_sr.slice(0, 30) + "...",
                  }}
                ></p>
              </div>
            </div>
          </>
        );
      }

    }
    );

  const pageCount = Math.ceil(events.length / casesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      <div className="container pt-5">
        <div className="row ">
          {displayCases}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreEvents);

