const MenuItems = [
  {
    url: "/Laws in Kosovo",
    title: "Laws in Kosovo",
  },
  {
    url: "/Awareness raising campaigns",
    title: "Awareness raising campaigns",
  },
  {
    url: "/Protection against discrimination",
    title: "Protection against discrimination",
  },
  {
    url: "/Annual reports",
    title: "Annual reports",
  },
  {
    url: "/Manuals for human rights",
    title: "Manuals for human rights",
  },
  {
    url: "/institutional mechanisms",
    title: "institutional mechanisms",
  },
];

export default MenuItems;
