import react, { useState, useEffect } from "react";
import "./Singleevent.scss";
import axios from "../../axios";
import Loader from "../loader/loader";

import { useParams } from "react-router";
const SingleEvent = (props) => {
  const [data, setdata] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [singleEvent, setsingleEvent] = useState();

  const { id } = useParams();

  // const myId = props.match.params.id;
  const getData = () => {
    axios
      .get("/general/events/all")
      .then((response) => {
        // console.log("te dhenat", response.data);
        setdata(response.data);
        // console.log("data", response?.data)
        setLoading(false);
      })

      .catch((err) => console.log("error", err));
  };
  const myD = () => {
    data.map((el) => {
      if (el.id == id) {
        setsingleEvent(el);
      } else return null;
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    myD();
  }, [data]);

  const Data = singleEvent;
  return (
    <>
      {isLoading == true ? (
        // <div>Loading</div>
        <Loader />
      ) : (

        <div className="container single_event">
          <div className="row mt-5 mb-5 test">
            <div className="col-md-6 col-12 ">
              <div className="border-img">
                <img
                  src={`${process.env.REACT_APP_UPLOADS}` + Data?.pic_url}
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-md-6 ">
              <h3 className="fw-bold mb-3">{Data?.name_al}</h3>
              <p className="mb-3"><i className="fa fa-lg fa-calendar"></i><span className="fw-bold"> From: </span>{Data?.from_date.slice(0, 10)} <span className="fw-bold"> - To: </span>{Data?.to_date.slice(0, 10)}</p>
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{ __html: Data?.desc_al }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleEvent;
