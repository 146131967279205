import React, { useEffect, useState } from "react";
import './Links.scss';

const Links = () => {
    const [selectedCase, setSelectedCase] = useState(0);
    const [color, setColor] = useState('red');

    useEffect(() => {
        getRandomMember()
    }, [selectedCase])


    const reportCase = [
        'Protection',
        'Laws',
        'Report',
        'Discrimination',
        'Anti-Gypsism',
        'Human Rights',
        'National',
        'Egyptian',
        'Kosovo',
        'Minorities',
        'Ashkali',
        'Roma',
        'Awareness',
        'Prevention'
    ]

    const getRandomMember = async () => {
        await new Promise((resolve, reject) => setTimeout(resolve, 1500));
        // setInterval(() => {
        let selectedElem = reportCase[Math.floor(Math.random() * reportCase.length)];
        // console.log(reportCase.indexOf(selectedElem));
        if (selectedCase != reportCase.indexOf(selectedElem)) {
            setSelectedCase(reportCase.indexOf(selectedElem))
        } else {
            setSelectedCase(reportCase.indexOf(selectedElem) + 1)
        }
        // }, 1000);
    }

    // getRandomMember()

    return (
        <>
            <a href="/report" >
                <section className="foto-links" id="html">
                    <div className="links row w-75 mx-auto">
                        {
                            reportCase.map((e, i) => {
                                if (i < 3) {
                                    if (i == 1) {
                                        return (
                                            <div className='col-md-1 col-5 first-row' key={i}>
                                                <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                            </div>
                                        )
                                    }
                                    else
                                        return (
                                            <div className='col-md-4 col-6 first-row' key={i}>
                                                <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                            </div>
                                        )
                                }
                                else
                                    if (i < 5) {
                                        if (i == 3) {
                                            return (
                                                <div className='col-md-4 col-5 second-row' key={i}>
                                                    <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                                </div>
                                            )
                                        }
                                        else
                                            return (
                                                <div className='col-md-8 col-6 second-row' key={i}>
                                                    <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                                </div>
                                            )
                                    } else
                                        if (i < 9) {
                                            if (i == 5) {
                                                return (
                                                    <div className='col-md-5 col-6 third-row' key={i}>
                                                        <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className='col-md-2 col-6 third-row' key={i}>
                                                    <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                                </div>
                                            )
                                        } else
                                            if (i < 13) {
                                                return (
                                                    <div className='col-md-4 col-6 fourth-row' key={i}>
                                                        <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`}> {e} </p>
                                                    </div>
                                                )
                                            } else
                                                return (
                                                    <div className='col-md-4 col-6 fifth-row' key={i}>
                                                        <p className={`link ${reportCase[i]} ${selectedCase == i ? "active" : ""}`} key={i}> {e} </p>
                                                    </div>)
                            })
                        }

                    </div>
                </section>
            </a>
        </>

    )

}

export default Links;