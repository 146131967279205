import React, { useEffect, useState } from "react";
import "./Laws.scss";
import axios from "../../axios";
import Loader from "../loader/loader";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
const LawsNationality = (props) => {
  const [laws, setLaws] = useState([]);
  const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    axios
      .get("/general/laws/all/national")
      .then((res) => {
        setLaws(res.data);
        setTimeout(() => setLoading(false), 500);

        // console.log("lawsNac", res);
      })
      .catch((err) => console.log("error", err));
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
      {iSloading == true ? (
        // <div>Loading</div>
        <Loader />
      ) : (
        <div className="container">
          <div className="col-12  ">
            <h1 className="text-center"> {translateMe("mainMenuLawsOpt1", props?.landId)}</h1>
            {laws?.map((e) => {
              if (props?.landId == "al") {
                return (
                  <>
                    <div className="pb-5">
                      <div className="accordion " id={"accordionExample" + e.id}>
                        <div className="accordion-item ">
                          {/* <h2 className="accordion-header" id="headingOne"> */}
                          <button
                            className="accordion-button "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + e.id}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <ul>
                              <li
                                style={{ fontWeight: 600 }}
                                value={e.id}
                                className=" pt-3"
                                dangerouslySetInnerHTML={{
                                  __html: e.name_al.slice(0, 50),
                                }}
                              ></li>
                            </ul>
                          </button>
                          {/* </h2> */}
                          <div
                            id={"collapseOne" + e.id}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent={"#accordionExample" + e.id}
                          >
                            <div className="accordion-body">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{ __html: e.desc_al }}
                              ></p>
                              <div className="url_created">
                                <p className="">
                                  <a href={e.url_al} target="_blank">
                                    Lexo me shume!
                                  </a>
                                  <small className="float-end me-5">
                                    data e publikimit te ligjit <br />
                                    {e.leg_date.slice(0, 10)}
                                    <i className=" float-end ms-2 mt-1 fa fa-calendar"></i>{" "}
                                  </small>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
              if (props?.landId == "en") {
                return (
                  <>
                    <div className="pb-5">
                      <div className="accordion " id={"accordionExample" + e.id}>
                        <div className="accordion-item ">
                          {/* <h2 className="accordion-header" id="headingOne"> */}
                          <button
                            className="accordion-button "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + e.id}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <ul>
                              <li
                                style={{ fontWeight: 600 }}
                                value={e.id}
                                className=" pt-3"
                                dangerouslySetInnerHTML={{ __html: e.name_en }}
                              ></li>
                            </ul>
                          </button>
                          {/* </h2> */}
                          <div
                            id={"collapseOne" + e.id}
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent={"#accordionExample" + e.id}
                          >
                            <div className="accordion-body">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{ __html: e.desc_en }}
                              ></p>
                              <div className="url_created">
                                <p className="">
                                  <a href={e.url_en} target="_blank">
                                    Read more!
                                  </a>
                                  <small className="float-end me-5">
                                    date of publication of the law <br />
                                    {e.leg_date.slice(0, 10)}
                                    <i className=" float-end ms-2 mt-1 fa fa-calendar"></i>{" "}
                                  </small>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="pb-5">
                      <div className="accordion " id={"accordionExample" + e.id}>
                        <div className="accordion-item ">
                          {/* <h2 className="accordion-header" id="headingOne"> */}
                          <button
                            className="accordion-button "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + e.id}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <ul>
                              <li
                                style={{ fontWeight: 600 }}
                                value={e.id}
                                className=" pt-3"
                                dangerouslySetInnerHTML={{ __html: e.name_sr }}
                              ></li>
                            </ul>
                          </button>
                          {/* </h2> */}
                          <div
                            id={"collapseOne" + e.id}
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent={"#accordionExample" + e.id}
                          >
                            <div className="accordion-body">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{ __html: e.desc_sr }}
                              ></p>
                              <div className="url_created">
                                <p className="">
                                  <a href={e.url_en} target="_blank">
                                    Read more!
                                  </a>
                                  <small className="float-end me-5">
                                    {e.leg_date.slice(0, 10)}
                                    <i className=" float-end ms-2 mt-1 fa fa-calendar"></i>{" "}
                                  </small>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LawsNationality);
