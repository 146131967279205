import React, { useState, useEffect } from "react";
import "./profile.scss";
import axios from "../../axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/loader";
import { BsChatLeftText } from "react-icons/bs";

import { connect } from "react-redux";

toast.configure();
const Profile = (props) => {
  const [edit, setEdit] = useState();
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [oldpassword, setoldpassword] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [reportCase, setReportCase] = useState([]);
  const [repeatpassword, setrepeatPassword] = useState("");
  const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  const handlefullname = (e) => {
    setFullname(e.target.value);
  };
  const handleusername = (e) => {
    setUsername(e.target.value);
  };
  const handleoldpassword = (e) => {
    setoldpassword(e.target.value);
  };
  const handlenewpassword = (e) => {
    setPassword(e.target.value);
  };
  const handlerepeatpassword = (e) => {
    setrepeatPassword(e.target.value);
  };

  useEffect(() => {
    axios
      .get(
        "/web/report_cases/findByUser/" + window.localStorage.getItem("id"),
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        // console.log("dataaa", res?.data);
        setReportCase(res?.data.slice(0, 2));
        setTimeout(() => setLoading(false), 200);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/web/user/" + window.localStorage.getItem("id"), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setEdit(res);
      })
      .catch((err) => { });
  }, []);
  const handleChangeUser = (e) => {
    e.preventDefault();
    let data = {
      full_name: fullname,
      username: username,
    };
    axios
      .put("/web/user/" + window.localStorage.getItem("id"), data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })

      .then((res) => {
        window.location = "/profile/" + window.localStorage.getItem("id");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    let data = {
      oldPass: oldpassword,
      pass: password,
    };

    let error = 0;
    if (password != repeatpassword) {
      error = 1;
      setError("passwordi juaj i ri nuk perputhet me new password");
    } else {
      setError("");
    }
    if (error == 0) {
      axios
        .put(
          "/web/user/editPassword/" + window.localStorage.getItem("id"),
          data,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data === "Please enter correct old password") {
            toast.error(`${res.data}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (res.data["updated"] === true) {
            toast.success("Your password has been changed ", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            window.location = "/profile/" + window.localStorage.getItem("id");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {iSloading == true ? (
        // <div>Loading</div>
        <Loader />
      ) : (
        <div className="profile">
          <div className="profile_layout">
            <div className="profile_layout_structure">
              <div className="info_profile mt-5">
                <h4 className="">
                  {" "}
                  {translateMe("hello", props?.landId)}, {edit?.data?.username}
                </h4>

                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#inputmodal"
                >
                  <p> {translateMe("editUsername", props?.landId)}</p>{" "}
                </button>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#passwordmodal"
                >
                  <p> {translateMe("editPassword", props?.landId)}</p>{" "}
                </button>
              </div>
            </div>

            <div
              className="modal fade"
              id="inputmodal"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {translateMe("username", props?.landId)}:{" "}
                      {edit?.data?.full_name}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form classNameName="" onSubmit={handleChangeUser}>
                      <div classNameName="row">
                        <div classNameName="col-3 w-50">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              {translateMe("username", props?.landId)}
                            </label>
                            <input
                              defaultValue={edit?.data?.username}
                              type="text"
                              className="form-control"
                              id="username"
                              onChange={handleusername}
                              aria-describedby="emailHelp"
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="submit" className="btn btnSave">
                          {translateMe("saveChanges", props?.landId)}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <form classNameName="" onSubmit={handleChange}>
              <div
                className="modal fade"
                id="passwordmodal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {translateMe("changePassword", props?.landId)}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div classNameName="col-3 w-50">
                        <div className="mb-3">
                          <label for="oldpassword" className="form-label">
                            {translateMe("oldPassword", props?.landId)}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="oldpassword"
                            onChange={handleoldpassword}
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                      <div classNameName="col-3 w-50">
                        <div className="mb-3">
                          <label for="newpassword" className="form-label">
                            {translateMe("newPassword", props?.landId)}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="newpassword"
                            onChange={handlerepeatpassword}
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                      <div classNameName="col-3 w-50">
                        <div className="mb-3">
                          <label for=" Repeatpassword" className="form-label">
                            {translateMe("repeatPassword", props?.landId)}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="Repeatpassword"
                            onChange={handlenewpassword}
                            aria-describedby="emailHelp"
                          />
                          <p className="text-danger">{error}</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btnSave">
                        {translateMe("saveChanges", props?.landId)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* {console.log(reportCase)} */}
            {!reportCase.length == 0 ? (
              <div className="boxes">
                <h3 className="text-center">
                  {" "}
                  {translateMe("yourReportedCases", props?.landId)}{" "}
                </h3>
                {reportCase?.map((e) => {
                  return (
                    <>
                      {/* <div className="boxes_layout" key={e.id}>
                    <h5>{e.cat_al}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e.case_?.slice(0, 60) + "...",
                      }}
                    ></p>
                  </div> */}
                      <div className="card    boxes_layout   card-border ">
                        <div className="card-body  p-5 bg-white">
                          <h5 className="card-title fs-4  pb-4">{e.cat_al}</h5>
                          <p className="card-text">
                            <span className="fw-bold">
                              {" "}
                              {translateMe("caseName", props?.landId)}:{" "}
                            </span>
                            {e.case_al}
                          </p>
                          <p>
                            <span className="fw-bold">
                              {" "}
                              {translateMe("city", props?.landId)}:{" "}
                            </span>{" "}
                            {e.municipality_al}
                          </p>
                          <p>
                            <span className="fw-bold">
                              {" "}
                              {translateMe("date", props?.landId)}:{" "}
                            </span>{" "}
                            {e.case_date.slice(0, 10)}
                          </p>
                          <Link to={{ pathname: "/chat/" + e.id }}>
                            <h5>
                              {translateMe("discussions", props?.landId)}{" "}
                              <BsChatLeftText />
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="text-center mt-3 pb-4 button">
                  <Link to={{ pathname: "/cases" }} className=" " style={{}}>
                    <p>{translateMe("allReportedCases", props?.landId)}</p>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="text-center d-flex flex-column justify-content-center">
                <div className="text-center">
                  <h1 className="ms-5 ps-5">
                    {" "}
                    {translateMe("noCasesYet", props?.landId)}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
