import React, { useEffect, useState } from "react";

// import { components } from 'react-select';

import "./loader.scss";

const Loader = () => {
  return (
    <div className="loaderWrapper">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>{" "}
    </div>
  );
};

export default Loader;
