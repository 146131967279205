import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "../../axios";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import { BsChatLeftText } from "react-icons/bs";
import { connect } from "react-redux";
import "../../App.css";
import "./Cases.scss";
const Cases = (props) => {
  const [reportCase, setReportCase] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    axios
      .get(
        "/web/report_cases/findByUser/" + window.localStorage.getItem("id"),
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        // console.log("dataaa111", res?.data);
        setReportCase(res?.data);
        setLoading();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const casesPerPage = 6;
  const pagesVisited = pageNumber * casesPerPage;
  if (iSloading == true) {
    return <Loader />;
  }
  const displayCases = reportCase
    .slice(pagesVisited, pagesVisited + casesPerPage)
    .map((e) => {
      return (
        <>
          <div className="col-md-4 mb-3 ">
            <div className="card     card-border ">
              <div className="card-body p-5 bg-white">
                <h5 className="card-title fs-4  pb-4">{e.cat_al}</h5>
                <p className="card-text">
                  <span className="fw-bold">{translateMe("caseName", props?.landId)}: </span>
                  {e.case_al}
                </p>
                <p>
                  <span className="fw-bold">{translateMe("city", props?.landId)}: </span> {e.municipality_al}
                </p>
                <p>
                  <span className="fw-bold">{translateMe("date", props?.landId)}: </span>{" "}
                  {e.case_date.slice(0, 10)}
                </p>
                <Link to={{ pathname: "/chat/" + e.id }}>
                  <h5>
                    {translateMe("discussions", props?.landId)} <BsChatLeftText />
                  </h5>
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    });


  const pageCount = Math.ceil(reportCase.length / casesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      <div className="container pt-5">
        <div className="row ">
          {displayCases}

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cases);
