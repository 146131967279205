import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import "./Laws.scss";
import { connect } from "react-redux";

const Laws = (propss) => {
  const [laws, setlaws] = useState([]);

  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    axios
      .get("/general/laws/all")
      .then((res) => {
        // console.log("datalaws", res?.data);
        setlaws(res?.data?.slice(0, 3));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log("xhevdet", laws);
  return (
    <>
      <section className="laws new-bg-color">
        <div className="">
          <div className="container">
            <h2 className="text-center mb-5">
              {translateMe("lawsTitle", propss?.landId)}
            </h2>
            <div className="row justify-content-center ">
              {laws?.map((el) => {
                if (propss?.landId == "al") {
                  return (
                    <>
                      <div className="col-lg-4 res_heigh  col-md-6 col-12 mb-4">
                        <div className="p-4  shadow">
                          <Link to={{ pathname: "/LawsNationality/" }}>
                            <h5
                              className="Card-title"
                              dangerouslySetInnerHTML={{
                                __html: el.name_al.slice(0, 30),
                              }}
                            >
                              { }
                            </h5>
                            <div className="card-body text-single ">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: el.desc_al?.slice(0, 120) + "...",
                                }}
                              ></p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                }
                if (propss?.landId == "en") {
                  // console.log("first", el?.name_al)
                  // console.log("first", el?.name_en)
                  return (
                    <>
                      <div className="col-lg-4 res_heigh  col-md-6 col-12 mb-4">
                        <div className="p-4  shadow">
                          <Link to={{ pathname: "/LawsInternacionality/" }}>
                            <h5
                              className="Card-title"
                              dangerouslySetInnerHTML={{
                                __html: el.name_en?.slice(0, 30),
                              }}
                            >
                              { }
                            </h5>
                            <div className="card-body text-single ">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: el.desc_en?.slice(0, 120) + "...",
                                }}
                              ></p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                }
                if (propss?.landId == "sr") {
                  // console.log("first", el?.name_al)
                  // console.log("first", el?.name_en)
                  return (
                    <>
                      <div className="col-lg-4 res_heigh  col-md-6 col-12 mb-4">
                        <div className="p-4  shadow">
                          <Link to={{ pathname: "/LawsInternacionality/" }}>
                            <h5
                              className="Card-title"
                              dangerouslySetInnerHTML={{
                                __html: el.name_sr?.slice(0, 30),
                              }}
                            >
                              { }
                            </h5>
                            <div className="card-body text-single ">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: el.desc_sr?.slice(0, 120) + "...",
                                }}
                              ></p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                }
                // console.log("first", el?.name_en)

              })}
            </div>
          </div>
        </div>
      </section>
      {/* <div className="laws  ">
        <h2 className="text-center  mb-5">
          {translateMe("lawsTitle", propss?.landId)}
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-4 ">
              {laws?.map((el) => {
                return (
                  <>
                    <div className="card-body ">
                      <Link to={{ pathname: "/LawsNationality/" }}>
                        <h5
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: el.name_al.slice(0, 30),
                          }}
                        >
                          {}
                        </h5>
                      </Link>{" "}
                      <p
                        className="w-75"
                        dangerouslySetInnerHTML={{
                          __html: el.desc_al?.slice(0, 130) + "...",
                        }}
                      ></p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Laws);
