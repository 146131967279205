import react, { useState, useEffect } from "react";
import axios from "../../axios";
import { useParams } from "react-router";
import "./singlelaws.scss";
const SingleLaws = (props) => {
  const [data, setdata] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [singleSerie, setsingleSerie] = useState();

  const { id } = useParams();

  // const myId = props.match.params.id;
  const getData = () => {
    axios
      .get("/general/laws/all")
      .then((response) => {
        // console.log("SingleLaws", response.data);
        setdata(response.data);
        setLoading(false);
      })

      .catch((err) => console.log("error", err));
  };
  const myD = () => {
    data.map((el) => {
      if (el.id == id) {
        setsingleSerie(el);
      } else return null;
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    myD();
  }, [data]);

  const Data = singleSerie;

  if (isLoading) {
    return <div></div>;
  } else
    return (
      <>
        <div className="singlelaws">
          <div className="container text-center w-50">
            <h1>{Data?.name_al}</h1>
            <p className="text-left"> {Data?.desc_al}</p>
          </div>
        </div>
      </>
    );
};

export default SingleLaws;
