import React, { useEffect, useState, Link } from "react";
import "./signup.scss";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import jwt from "jwt-decode";
toast.configure();
const Signup = (props) => {
  const [errorF, setErrorF] = useState("");
  const [errorU, setErrorU] = useState("");

  const [test, setTest] = useState();
  const [errorP, setErrorP] = useState("");
  const [fullName, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [edukimi, setEdukimi] = useState("");
  const [employment, setEmployment] = useState("");
  const [Gjinia, setGjinia] = useState("");
  const [community, setcommunity] = useState([]);
  const [communitySend, setcommunitySend] = useState("");
  const [addressa, setaddresa] = useState("");
  const [age, setage] = useState("");
  const [privacy, setPrivacy] = useState([]);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  // const handlefullname = (e) => {
  //   setFullname(e.target.value);
  //   setErrorF("");
  // };
  // const handlegjinia = (e) => {
  //   setGjinia(e.target.value);
  // };
  // const handleEduaction = (e) => {
  //   setEdukimi(e.target.value);
  // };
  // const handleEmployment = (e) => {
  //   setEmployment(e.target.value);
  // };
  // const handleage = (e) => {
  //   setage(e.target.value);
  // };
  // const handleaddresa = (e) => {
  //   setaddresa(e.target.value);
  // };
  // const handlecommunity = (e) => {
  //   setcommunitySend(e.target.value);
  // };

  const handleusername = (e) => {
    setUsername(e.target.value);
    setErrorU("");
  };

  const handlepassword = (e) => {
    setPassword(e.target.value);
    setErrorP("");
  };
  // console.log("gjinia", Gjinia);
  useEffect(() => {
    axios
      .get("/general/privacy_policy")
      .then((res) => {
        setPrivacy(res?.data);
        // console.log("privacy", res);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      // full_name: fullName,
      username: username,
      pass: password,
      // gender: Gjinia,
      // community: communitySend,
      // address: addressa,
      // education: edukimi,
      // employment_status: employment,
      // age: age,
    };
    // console.log("genderdate", data);

    let error = 0;
    // if (fullName == "") {
    //   setErrorF("Please write your full name!");
    //   error = 1;
    // }
    if (username == "") {
      setErrorU(`${translateMe("writeYourUsername", props?.landId)}`);
      error = 1;
    }

    if (password == "") {
      setErrorP(`${translateMe("writeYourPassword", props?.landId)}`);
      error = 1;
    }
    if (error == 0) {
      axios
        .post("/general/register", data)
        .then((res) => {
          // console.log("register", res?.data);

          if (res?.data === "User exists!") {
            toast.error(`${res.data}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.success(`${res.data}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            axios
              .post("/general/login", data)
              .then((res) => {
                if (res?.data?.token) {
                  toast.success("Welcome!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  window.localStorage.setItem("token", res.data.token);
                  const user = jwt(res.data.token);
                  window.localStorage.setItem("id", user.id);
                  setTest(res.data.token);

                  window.location.href = "/";
                } else if (res.data.error) {
                  toast.error(`${res.data.error}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="signupForm">
        <div className="singupform_layout">
          <form name="myform" onSubmit={handleSubmit}>
            <h1 className="text-center">
              {translateMe("register", props?.landId)}
            </h1>

            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                {translateMe("username", props?.landId)}
              </label>
              <input
                name="username"
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                onChange={handleusername}
              />
              {
                <div className="text-danger">
                  <p>{errorU}</p>
                </div>
              }
            </div>

            <div className="mb-3 ">
              <label for="exampleInputPassword1" className="form-label">
                {translateMe("password", props?.landId)}
              </label>
              <input
                name="password"
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                minLength="8"
                onChange={handlepassword}
              />
            </div>
            {
              <div className="text-danger">
                <p>{errorP}</p>
              </div>
            }

            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              {/* <Link
                to="/Privacy"
              >
                              {translateMe("PrivacyPolicy", props?.landId)}

              </Link> */}
              <label for="example" className="form-label pe-2">
                {translateMe("termsofUse", props?.landId)}
              </label>
              <a href="/Terms">{translateMe("TermsofUse", props?.landId)}</a>
              <label for="example" className="form-label ps-2">
                {translateMe("and", props?.landId)}
              </label>
              <a href="/Privacy">{translateMe("privacy", props?.landId)}</a>
            </div>
            <button className="submit_button w-100">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
