import react, { useState } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./data/menuitems";
import { connect } from "react-redux";
import { setLangID, setLangData } from "../../redux/Functions/actions";

const Mobile = (props) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  return (
    <>
      <div className="mobileMenu">
        <div className="row w-100">
          <div className="col-10"></div>
          <div className="col-2">
            <button onClick={handleClick}>
              {isOpen ? (
                <i className="fa fa-times" />
              ) : (
                <i className="fa fa-bars" />
              )}
            </button>
          </div>
        </div>
      </div>
      {!!isOpen && (
        <div className="mobileMenuContent">
          <ul>
            <li>
              <Link
                to="/"
                className="text-light  text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuHome", props?.landId)}
              </Link>
              <Link
                to="/aboutus"
                className="text-light  text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuAboutUs", props?.landId)}
              </Link>
              {/* <Link
                to="/events"
                className="text-light text-decoration-none"
                onClick={handleClick}
              >
                Events{" "}
              </Link> */}
              <Link
                to="/news"
                className="text-light  text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuNews", props?.landId)}
              </Link>
              <div className="dropdown position-relative d-table">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingLeft: "4px",
                    fontWeight: 500,
                  }}
                >
                  {translateMe("mainMenuLaws", props?.landId)}
                </button>
                <ul
                  className="dropdown-menu drop_background"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    {" "}
                    <Link
                      to="/LawsNationality"
                      className="text-light  text-decoration-none"
                      onClick={handleClick}
                    >
                      {translateMe("mainMenuLawsOpt1", props?.landId)}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      to="/LawsInternacionality"
                      className="text-light  text-decoration-none "
                      onClick={handleClick}
                    >
                      {translateMe("mainMenuLawsOpt2", props?.landId)}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      to="/Constitution"
                      className=" text-light  text-decoration-none"
                      onClick={handleClick}
                    >
                      {translateMe("mainMenuLawsOpt3", props?.landId)}
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/mision"
                className="text-light text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuMission", props?.landId)}
              </Link>

              <Link
                to="/DiversityAndEquality"
                className="text-light text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("topMenuDiversity", props?.landId)}
              </Link>

              <Link
                to="/protection"
                className="text-light text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuProtection", props?.landId)}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
// export default Mobile;

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    setLangID: (id) => dispatch(setLangID(id)),
    setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);

