import React from "react";
import "./Home.scss";
import About from "../Aboutus/Aboutus";
import News from "../News/News";
import Events from "../Events/Events";
import Slider from "../Slider/Slider";
import Laws from "../Laws/Laws";
import Links from "../Links/Links";
import Partnert from "../partneret/partner";
import HowToUse from "../howToUse/howToUse";

const Home = () => {
  return (
    <>
      <Slider />
      <Links />
      <About />
      <HowToUse />
      <Laws />
      <News />
      {/* <Events /> */}
      <Partnert />
    </>
  );
};

export default Home;
