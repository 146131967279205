import React, { useState, useEffect } from "react";
import Loader from "../loader/loader";

import "./Error404.scss";
import { connect } from "react-redux";

const Error404 = (propss) => {
  const [iSloading, setLoading] = useState(true);

  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    setLoading();
  }, []);
  if (iSloading == true) {
    return <Loader />;
  }
  return (
    <div className="container">
      <div className="col-md-12 text-center mb-5 mt-5">
        <div>

          <h2>404 {translateMe("404", propss?.landId)}</h2>
          <div>{translateMe("test", propss?.landId)}</div>
          <div className="home mt-5">
            <a href="/" className="btn btn-info text-white">
              {translateMe("backToHome", propss?.landId)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

// import React, { useEffect, useState } from "react";
// import "./Aboutus.scss";
// import axios from "../../axios";
// import LanguageHandler from "../LanguageHandler";
// import useTranslation from "../customHooks/translations";

// const About = () => {
//   const [laws, setLaws] = useState([]);

//   useEffect(() => {
//     axios
//       .get("/general/aboutus")
//       .then((res) => {
//         setLaws(res.data);
//         console.log("laws", res);
//       })
//       .catch((err) => console.log("error", err));
//   }, []);

//   const translation = useTranslation();

//   return (
//     <>
//       <div className="container">
//         <h1>asdasdadasassadf</h1>
//         {laws?.map((e) => {
//           return (
//             <>
//               <div className="boxes_layout">
//                 <p dangerouslySetInnerHTML={{ __html: e.content_al }}></p>
//               </div>
//             </>
//           );
//         })}
//       </div>
//     </>
//   );
// };

// export default About;

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error404);
