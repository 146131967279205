import React, { useEffect, useState, Link } from "react";
import "../../App.css";
import axios from "../../axios";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../loader/loader";

const Privacy = (props) => {
    const [privacyPolicies, setprivacyPolicies] = useState([]);
    const [iSloading, setLoading] = useState(true);
    const translateMe = (key, langId) => {
        let diqka = props?.langData?.filter((el) => el.key == key)[0];
        if (langId === "al") {
            return diqka?.content_al;
        }
        if (langId === "sr") {
            return diqka?.content_sr;
        }
        if (langId === "en") {
            return diqka?.content_en;
        }
    };
    useEffect(() => {
        axios
            .get("/general/privacy_policy")
            .then((res) => {
                setprivacyPolicies(res.data);
                setTimeout(() => setLoading(false), 500);
            })
            .catch((err) => console.log("error", err));
    }, []);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <>
            {iSloading == true ? (
                // <div>Loading</div>
                <Loader />
            ) : (
                <div className="container ">
                    <div className="linkpageAbout">
                        <div className="col-12 ">
                            <h1 className="text-center">{translateMe("PrivacyPolicy", props?.landId)}</h1>
                            {privacyPolicies?.map((e) => {
                                if (props?.landId == "al") {
                                    return (
                                        <>
                                            <div className="aboutUsLaw">
                                                <p
                                                    className=""
                                                    dangerouslySetInnerHTML={{ __html: e.content_al }}
                                                ></p>
                                            </div>
                                        </>
                                    );
                                }
                                if (props?.landId == "en") {
                                    return (
                                        <>
                                            <div className="aboutUsLaw">
                                                <p
                                                    className=""
                                                    dangerouslySetInnerHTML={{ __html: e.content_en }}
                                                ></p>
                                            </div>
                                            ;
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <div className="aboutUsLaw">
                                                <p
                                                    className=""
                                                    dangerouslySetInnerHTML={{ __html: e.content_sr }}
                                                ></p>
                                            </div>
                                        </>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        // loggedIn: state.data.loggedIn,
        // reportData: state.data.reportData,
        landId: state.data.langId,
        langData: state.data.langData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // logMeIn: () => dispatch(logMeIn()),
        // setReportData: (data) => dispatch(setReportData(data)),
        // Clear: () => dispatch(Clear()),
        // setLangID: (id) => dispatch(setLangID(id)),
        // setLangData: (data) => dispatch(setLangData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
