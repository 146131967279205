import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import "./howToUse.scss";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import myVideo from "./howToUse.mov";


const HowToUse = (propss) => {
    const [lawsHome, setLawsHome] = useState([]);

    const translateMe = (key, langId) => {
        let diqka = propss?.langData?.filter((el) => el.key == key)[0];
        if (langId === "al") {
            return diqka?.content_al;
        }
        if (langId === "sr") {
            return diqka?.content_sr;
        }
        if (langId === "en") {
            return diqka?.content_en;
        }
    };

    return (
        <>
            <div className="container">
                <div className="howToUse">
                    <h1 className="text-center ">{translateMe("howToUse", propss?.landId)} </h1>

                    <video controls preload muted loop >
                        <source src={myVideo} type="video/mp4"></source>
                    </video>
                </div>
                {/* <div className="mt-5 text-center btn-about ">
                        <Link
                            to="/aboutus"
                            className=" w-100 text-center mt-4 p-2 text-decoration-none"
                        >
                            {translateMe("showMore", propss?.landId)}
                        </Link>
                    </div> */}

            </div>
        </>
    );
    // });

};

const mapStateToProps = (state) => {
    return {
        // loggedIn: state.data.loggedIn,
        // reportData: state.data.reportData,
        landId: state.data.langId,
        langData: state.data.langData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // logMeIn: () => dispatch(logMeIn()),
        // setReportData: (data) => dispatch(setReportData(data)),
        // Clear: () => dispatch(Clear()),
        // setLangID: (id) => dispatch(setLangID(id)),
        // setLangData: (data) => dispatch(setLangData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HowToUse);
