import React, { useState, useEffect } from "react";
import "./Events.scss";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { connect } from "react-redux";

const Events = (propss) => {
  const [events, setEvents] = useState([]);

  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    axios
      .get("/general/events/all")
      .then((res) => {
        setEvents(res?.data.slice(0, 3));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section className="events ">
        <div className="container">
          <div className="text-upper text-center pt-5">
            <h1 className="text-events">
              {translateMe("eventsTitle", propss?.landId)}
            </h1>
            <p className="w-75 mx-auto">
              {translateMe("eventsContent", propss?.landId)}

            </p>
          </div>

          <div className="row justify-content-center card-events">
            {events?.map((el) => {
              if (propss?.landId == "al") {
                return (
                  <>
                    <div className="col-lg-4 col-md-6 col-10 bg-img " key={el.id}>
                      <div
                        className="text-lower shadow"
                        style={{
                          backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.50)), url("${`${process.env.REACT_APP_UPLOADS}` + el.pic_url
                            }`,
                        }}
                      >
                        <p className="date">
                          {" "}
                          <i className="fa fa-calendar"></i>{" "}
                          {el.from_date.slice(0, 10)}
                        </p>

                        <Link to={{ pathname: "/event/" + el.id }}>

                          <h5> {el.name_al}</h5>
                          {translateMe("mainMenuEvents", propss?.landId)}
                        </Link>
                        <p
                          className="event-desc"
                          dangerouslySetInnerHTML={{
                            __html: el.desc_al.slice(0, 30) + "...",
                          }}
                        ></p>
                      </div>
                    </div>
                  </>
                )
              }
              if (propss?.landId == "en") {
                return (
                  <>
                    <div className="col-lg-4 col-md-6 col-10 bg-img " key={el.id}>
                      <div
                        className="text-lower shadow"
                        style={{
                          backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.50)), url("${`${process.env.REACT_APP_UPLOADS}` + el.pic_url
                            }`,
                        }}
                      >
                        <p className="date">
                          {" "}
                          <i className="fa fa-calendar"></i>{" "}
                          {el.from_date.slice(0, 10)}
                        </p>

                        <Link to={{ pathname: "/event/" + el.id }}>

                          <h5> {el.name_en}</h5>
                          {translateMe("mainMenuEvents", propss?.landId)}
                        </Link>
                        <p
                          className="event-desc"
                          dangerouslySetInnerHTML={{
                            __html: el.desc_en.slice(0, 30) + "...",
                          }}
                        ></p>
                      </div>
                    </div>
                  </>
                )
              }
              if (propss?.landId == "sr") {
                return (
                  <>
                    <div className="col-lg-4 col-md-6 col-10 bg-img " key={el.id}>
                      <div
                        className="text-lower shadow"
                        style={{
                          backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.50)), url("${`${process.env.REACT_APP_UPLOADS}` + el.pic_url
                            }`,
                        }}
                      >
                        <p className="date">
                          {" "}
                          <i className="fa fa-calendar"></i>{" "}
                          {el.from_date.slice(0, 10)}
                        </p>

                        <Link to={{ pathname: "/event/" + el.id }}>

                          <h5> {el.name_sr}</h5>
                          {translateMe("mainMenuEvents", propss?.landId)}
                        </Link>
                        <p
                          className="event-desc"
                          dangerouslySetInnerHTML={{
                            __html: el.desc_sr.slice(0, 30) + "...",
                          }}
                        ></p>
                      </div>
                    </div>
                  </>
                )
              }

            })}
          </div>

          <div className="btn-events text-center">
            <Link to={{ pathname: "/events" }} className="btn">
              {translateMe("showMore", propss?.landId)}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
