import React from "react";
import { useState, useEffect } from "react";
import axios from "../../axios";
import MenuItems from "./data/menuitems";
import "./Header.scss";
import { Link } from "react-router-dom";
import Login from "../login/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "./images/Logoshqip.png";
import rks from "./images/rks.png";
import News from "../News/News";
import mision from "../mision/mision";
import Mobile from "./mobile";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setLangID, setLangData } from "../../redux/Functions/actions";

toast.configure();
const Header = (props) => {
  const [user, setUser] = useState();
  const [active, setActive] = useState();

  const removeItems = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("id");
    window.localStorage.clear();
    setTimeout(() => { }, 300);
  };

  // jena duke i mar krejt labelat edhe tu i atachu ne redux s
  // tate qe me mujt me pase akses nga krej applikacioni
  const getTranslations = () => {
    axios
      .get("/general/translations/web/all")
      .then((data) => {
        // console.log("translationData", data.data);
        props.setLangData(data.data);
      })
      .catch((err) => console.log(err));
  };

  // ky eshte funkcioni i cili varet nga ndryshimi i gjuhes dhe ne baze te saj
  // dhe key qe jemi duke kerkuar na kthen contentin e duhur
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  // console.log("LANGUAGEID",props?.landId)

  // funkcioni me posht e ndryshon statin e gjuhes ne redux
  //nga i cili ndryshon edhe qfar kthejne perkthimet
  const handleLangChange = (e) => {
    // console.log("languageSelected", e.target.value);
    props.setLangID(e.target.value);
  };

  useEffect(() => {
    getTranslations();
    axios
      .get("/mobile/user/" + window.localStorage.getItem("id"), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
      <div className="header1">
        <div className="header-part1">
          <div className="container">
            <div className="part1">
              <div className="link">
                <Link
                  to={"/profile/" + window.localStorage.getItem("id")}
                  style={{
                    display:
                      localStorage.getItem("token") !== null ? "" : "none",
                  }}
                >
                  {user?.data?.username}
                </Link>
                {/* shembulli si e thirrim nje label translateMe('topMenuManuals', props.landId)  */}
                <Link to="Manuals">
                  {translateMe("topMenuManuals", props?.landId)}{" "}
                </Link>
                {/* <Link to="Contact Us">
                  {translateMe("topMenuContact", props?.landId)}
                </Link> */}
                <a href="/signup">{translateMe("signup", props?.landId)}</a>
                <Link
                  to=""
                  data-bs-toggle="modal"
                  data-bs-target="#login-or-register"
                  style={{
                    display:
                      localStorage.getItem("token") !== null ? "none" : "",
                  }}
                >
                  {translateMe("login", props?.landId)}
                </Link>
                <Link
                  to="/"
                  onClick={removeItems}
                  style={{
                    display:
                      localStorage.getItem("token") !== null ? "" : "none",
                  }}
                >
                  {translateMe("topMenuLogOut", props?.landId)}
                </Link>
                <div className="language">
                  <select onChange={handleLangChange}>
                    {/* <option hidden>...</option> */}
                    <option
                      value={"al"}
                      selected={props?.landId == "al" ? "selected" : ""}
                    >
                      ALB
                    </option>
                    <option
                      value={"en"}
                      selected={props?.landId == "en" ? "selected" : ""}
                    >
                      ENG
                    </option>
                    <option
                      value={"sr"}
                      selected={props?.landId == "sr" ? "selected" : ""}
                    >
                      SRB
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header" id="myHeader">
          <div className="header-part2">
            <div className="container">
              <div className="part2">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} alt="logo" />
                  </Link>
                </div>
                <div className="rks">
                  <a href="/">
                    <img className="" src={rks} alt="Logo" />
                  </a>
                </div>

                <Link to="/report" className="btn main-button">
                  {translateMe("menuHeaderReportButton", props?.landId)}
                  {/* Report a case */}
                </Link>
                <div
                  className="modal fade"
                  id="login-or-register"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <Login />
                      </div>
                      <div className="modal-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-part3">
            <div className="container">
              <div className="part3">
                <li className={splitLocation[1] === "" ? "active" : ""}>
                  <Link
                    to="/"
                    className="text-light m-3 p-2 text-decoration-none"
                  >
                    {translateMe("mainMenuHome", props?.landId)}

                    {/* Home */}
                  </Link>
                </li>
                <li className={splitLocation[1] === "news" ? "active" : ""}>
                  <Link
                    to="/news"
                    className="text-light m-3 p-2 text-decoration-none"
                  >
                    {translateMe("mainMenuNews", props?.landId)}

                    {/* News */}
                  </Link>
                </li>
                <li className={splitLocation[1] === "aboutus" ? "active" : ""}>
                  <Link
                    to="/aboutus"
                    className="text-light  m-3 p-2 text-decoration-none"
                  >
                    {translateMe("mainMenuAboutUs", props?.landId)}

                    {/* About Us */}
                  </Link>
                </li>

                <li className={splitLocation[1] === "Mision" ? "active" : ""}>
                  <Link
                    to="/Mision"
                    className="text-light   m-3 p-2 text-decoration-none"
                  >
                    {translateMe("mainMenuMission", props?.landId)}

                    {/* Mission */}
                  </Link>
                </li>
                {/* 
                <li className={splitLocation[1] === "events" ? "active" : ""}>
                  <Link
                    to="/events"
                    className="text-light  m-3 p-2 text-decoration-none"
                  >
                    {translateMe("mainMenuEvents", props?.landId)}

                  </Link>
                </li> */}

                <div className="dropdown">
                  <button
                    className="btn btn-secondary  dropdownLaws p-2  "
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    {translateMe("mainMenuLaws", props?.landId)}

                    {/* Laws */}
                    <i className="fa fa-caret-down text-light ms-3 "></i>
                  </button>
                  <ul
                    className="dropdown-menu drop_background"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        to="/LawsNationality"
                        className="text-light text-decoration-none "
                      >
                        {translateMe("mainMenuLawsOpt1", props?.landId)}

                        {/* Nacional Laws */}
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        to="/LawsInternacionality"
                        className="text-light  text-decoration-none "
                      >
                        {translateMe("mainMenuLawsOpt2", props?.landId)}

                        {/* Internacional Laws */}
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        to="/Constitution"
                        className=" text-light  text-decoration-none"
                      >
                        {translateMe("mainMenuLawsOpt3", props?.landId)}

                        {/* Constitution */}
                      </Link>
                    </li>
                  </ul>
                </div>
                <li
                  className={
                    splitLocation[1] === "DiversityAndEquality" ? "active" : ""
                  }
                >
                  <Link
                    to="/DiversityAndEquality"
                    className=" text-light  text-decoration-none  m-3 p-2 "
                  >
                    {translateMe("topMenuDiversity", props?.landId)}

                    {/* {translateMe("mainMenuLawsOpt3", props?.landId)} */}

                    {/* Constitution */}
                  </Link>
                </li>
                <li
                  className={splitLocation[1] === "protection" ? "active" : ""}
                >
                  <Link
                    to="/protection"
                    className="text-light  m-3 p-2 text-decoration-none"
                  >
                    {translateMe("mainMenuProtection", props?.landId)}

                    {/* Protection against discrimination */}
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </div>
        <Mobile />
      </div >
    </>
  );
};
// document.addEventListener('DOMContentLoaded', function() {
// window.onscroll = function() {myFunction()};
// var header = document.getElementById("myHeader");
// var sticky = header.offsetTop;

// function myFunction() {
//   if (window.pageYOffset >= sticky) {
//     header.classList.add("sticky");
//   } else {
//     header.classList.remove("sticky");
//   }
// }
// })

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    setLangID: (id) => dispatch(setLangID(id)),
    setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
