import React, { useState, useEffect, useForm } from "react";
import "./login.scss";
import axios from "../../axios";
import { Link } from "react-router-dom";

import jwt from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

toast.configure();
const Login = (props) => {
  const [error, setError] = useState("");
  const [errorPassword, seterrorPassword] = useState("");
  const [wrong, setWrong] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [test, setTest] = useState();
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  const handleusername = (e) => {
    setUsername(e.target.value);
    setError("");
  };
  const handlepassword = (e) => {
    setPassword(e.target.value);
    seterrorPassword("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      username: username,
      pass: password,
    };
    let error = 0;

    if (username === "") {
      setError(`${translateMe("writeYourUsername", props?.landId)}`);
      error = 1;
    }
    if (password === "") {
      seterrorPassword(`${translateMe("writeYourPassword", props?.landId)}`);
      error = 1;
    }

    if (error == 0) {
      axios
        .post("/general/login", data)
        .then((res) => {
          if (res?.data?.token) {
            toast.success("Welcome!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            window.localStorage.setItem("token", res.data.token);
            const user = jwt(res.data.token);
            window.localStorage.setItem("id", user.id);
            setTest(res.data.token);

            window.location.href = "";
          } else if (res.data.error) {
            toast.error(`${res.data.error}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="loginform_layout">
        <form onSubmit={handleSubmit} name="myform">
          <h1 className="text-center">{translateMe("login", props?.landId)}</h1>
          <div className="mb-3">
            <p className="text-danger">{wrong}</p>
            <label htmlFor="exampleInputEmail1" className="form-label">
              {translateMe("username", props?.landId)}
            </label>

            <input
              type="text"
              className="form-control"
              id="username"
              name="uname"
              onChange={handleusername}
            />
            <p className="text-danger">{error}</p>
          </div>
          <div className="mb-3 ">
            <label htmlFor="exampleInputPassword1" className="form-label">
              {translateMe("password", props?.landId)}
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              onChange={handlepassword}
            />
            <p className="text-danger">{errorPassword}</p>
          </div>

          <button type="submit" className="submit_button w-100">
            Submit
          </button>
        </form>
        <p>
          {translateMe("account", props?.landId)}{" "}
          <Link to="/signup"> {translateMe("signup", props?.landId)} </Link>
          {}
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
