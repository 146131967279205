import React from "react";
import "./Slider.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SliderImage1 from "./images/SliderImage.jpg";
import SliderImage2 from "./images/SliderImage2.jpg";
import SliderImage3 from "./images/SliderImage3.jpg";
import SliderImage4 from "./images/SliderImage4.jpg";
import SliderImageNew41 from "./images/SliderImageNew41.jpg";
import Slider_logo from "../images/slider_logo.png";
import leonita from "./images/leonita.png";
import app from "./images/app-store-badge.png";
import android from "./images/google-play-badge.png";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Slide } from "react-toastify";
// const  Slider (props)  = (props) => {
const Slider = (props) => {
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      interval={2000}
      emulateTouch
      // autoPlay
      autoFocus
      stopOnHover
      infiniteLoop
      className=" carousel"
    >
      <div>
        <img src={SliderImage1} className="slider-image float-end" />
        <div className="svg">
          <img src={leonita} className=" position-absolute  start-0 w-auto" />
        </div>

        <div className="container text-start logo">
          <img src={Slider_logo} className="slider-logo mb-3" />
          <p className={"slider-text fw-bold text-light mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
      <div>
        <img src={SliderImage2} className="slider-image float-end" />
        <div className="svg">
          <img src={leonita} className=" position-absolute  start-0 w-auto" />
        </div>
        <div className="container text-start">
          <img src={Slider_logo} className="slider-logo mb-3" />

          <p className={"slider-text fw-bold text-light mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
      <div>
        <img src={SliderImage3} className="slider-image float-end" />
        <div className="svg">
          <img src={leonita} className=" position-absolute  start-0 w-auto" />
        </div>
        <div className="container text-start">
          <img src={Slider_logo} className="slider-logo mb-3" />

          <p className={"slider-text fw-bold text-light mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
      <div>
        <img src={SliderImageNew41} className="slider-image float-end" />
        <div className="svg">
          <img src={leonita} className=" position-absolute  start-0 w-auto" />
        </div>
        <div className="container text-start">
          <img src={Slider_logo} className="slider-logo mb-3" />
          <p className={"slider-text fw-bold text-light mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
    </Carousel>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
