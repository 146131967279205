import react, { useState, useEffect } from "react";
import axios from "../../axios";
import "./Singlenews.scss";
import Loader from "../loader/loader";
import { useParams } from "react-router";
import { connect } from "react-redux";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton, LinkedinIcon, LinkedinShareButton
} from "react-share";


const Singlenews = (props) => {
  const [data, setdata] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [singleNews, setsingleNews] = useState();
  const { id } = useParams();
  const shareUrl = `https://raportodiskriminimin.org/news/${id}`
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };


  const getData = () => {
    axios
      .get("/general/news/all")
      .then((response) => {
        // console.log("te dhenat", response.data);
        setdata(response.data);
        setLoading(false);
      })

      .catch((err) => console.log("error", err));
  };
  const myD = () => {
    data.map((el) => {
      if (el.id == id) {
        setsingleNews(el);
      } else return null;
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    myD();
  }, [data]);

  const Data = singleNews;

  if (isLoading == true) {
    return <Loader />;
  }
  if (props?.landId == "al") {
    return (
      <>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-5 col-10 ">
              <div className="border-img">

                {Data?.pic_url ? <img
                  src={`${process.env.REACT_APP_UPLOADS}` + Data?.pic_url}
                  className="card-img-top"
                  alt="..."
                /> : <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />}
              </div>
            </div>
            <div className="col-md-7">
              <h5 className="fw-bold">{Data?.name_al}</h5>
              <div className="" dangerouslySetInnerHTML={{ __html: Data?.desc_al }}></div>
              <div className="allIcons">
                <div className="smallIcons">
                  <FacebookShareButton
                    url={shareUrl}
                  >
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                </div>
                <div className="smallIcons">

                  <TwitterShareButton
                    url={shareUrl}
                  >
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                </div>

                <div className="smallIcons">
                  <LinkedinShareButton
                    url={shareUrl}
                  >
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  if (props?.landId == "en") {
    return (
      <>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-5 col-10 ">
              <div className="border-img">

                {Data?.pic_url ? <img
                  src={`${process.env.REACT_APP_UPLOADS}` + Data?.pic_url}
                  className="card-img-top"
                  alt="..."
                /> : <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />}
              </div>
            </div>
            <div className="col-md-7">
              <h5 className="fw-bold">{Data?.name_en}</h5>
              <div className="" dangerouslySetInnerHTML={{ __html: Data?.desc_en }}></div>
              <div className="allIcons">
                <div className="smallIcons">
                  <FacebookShareButton
                    url={shareUrl}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>
                <div className="smallIcons">

                  <TwitterShareButton
                    url={shareUrl}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>

                <div className="smallIcons">
                  <LinkedinShareButton
                    url={shareUrl}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  if (props?.landId == "sr") {
    return (
      <>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-5 col-10 ">
              <div className="border-img">

                {Data?.pic_url ? <img
                  src={`${process.env.REACT_APP_UPLOADS}` + Data?.pic_url}
                  className="card-img-top"
                  alt="..."
                /> : <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />}
              </div>
            </div>
            <div className="col-md-7">
              <h5 className="fw-bold">{Data?.name_sr}</h5>
              <div className="" dangerouslySetInnerHTML={{ __html: Data?.desc_sr }}></div>
              <div className="allIcons">
                <div className="smallIcons">
                  <FacebookShareButton
                    url={shareUrl}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>
                <div className="smallIcons">

                  <TwitterShareButton
                    url={shareUrl}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>

                <div className="smallIcons">
                  <LinkedinShareButton
                    url={shareUrl}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Singlenews);
