import React, { useEffect, useState, Link } from "react";
import "./protection.scss";
import axios from "../../axios";
import Loader from "../loader/loader";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const Protection = (props) => {
  const [Protection, setProtection] = useState([]);
  const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  useEffect(() => {
    axios
      .get("general/protection_against_discrimination/all")
      .then((res) => {
        setProtection(res.data);
        setTimeout(() => setLoading(false), 200);
        // console.log("Protection", res);
      })
      .catch((err) => console.log("error", err));
  }, []);

  return (
    <>
      {iSloading == true ? (
        // <div>Loading</div>
        <Loader />
      ) : (
        <div className="container">
          <div className="col-12  ">
            .<h1 className="text-center">{translateMe("mainMenuProtection", props?.landId)}</h1>
            {Protection?.map((e) => {
              if (props?.landId == "en") {
                return (
                  <>
                    <div className="aboutUsLaw">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          {/* <h2 className="accordion-header" id="headingOne"> */}
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + e.id}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <ol>
                              <li
                                style={{ fontWeight: 600 }}
                                value={e.id}
                                className="pt-3"
                                dangerouslySetInnerHTML={{ __html: e.title_en }}
                              ></li>{" "}
                            </ol>
                          </button>
                          {/* </h2> */}
                          <div
                            id={"collapseOne" + e.id}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{ __html: e.desc_en }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
              if (props?.landId == "al") {
                return (
                  <>
                    <div className="aboutUsLaw">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          {/* <h2 className="accordion-header" id="headingOne"> */}
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + e.id}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <ol>
                              <li
                                style={{
                                  fontWeight: 600,
                                }}
                                value={e.id}
                                className="pt-3"
                                dangerouslySetInnerHTML={{
                                  __html: e.title_al,
                                }}
                              ></li>{" "}
                            </ol>
                          </button>
                          {/* </h2> */}
                          <div
                            id={"collapseOne" + e.id}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: e.desc_al,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="aboutUsLaw">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          {/* <h2 className="accordion-header" id="headingOne"> */}
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + e.id}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <ol>
                              <li
                                style={{ fontWeight: 600 }}
                                value={e.id}
                                className="pt-3"
                                dangerouslySetInnerHTML={{ __html: e.title_sr }}
                              ></li>{" "}
                            </ol>
                          </button>
                          {/* </h2> */}
                          <div
                            id={"collapseOne" + e.id}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p
                                className=""
                                dangerouslySetInnerHTML={{ __html: e.desc_sr }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Protection);
