import React, { useState, useEffect } from "react";
import Loader from "../loader/loader";
import axios from "../../axios";

import { connect } from "react-redux";

import "./partner.scss";
const Partnert = (propss) => {
  const [iSloading, setLoading] = useState(true);
  const [partnerIMG, setpartnerIMG] = useState();

  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    axios
      .get("/general/partners")
      .then((res) => {
        setpartnerIMG(res.data);
      })
      .catch((err) => console.log("error", err));
  }, []);
  // console.log("set", partnerIMG);
  useEffect(() => {
    setLoading();
  }, []);
  if (iSloading == true) {
    return <Loader />;
  }
  return (
    <>
      <div className="partneret">
        <div className="container">
          <h2 className="text-center mb-5 mt-5">
            {translateMe("ourPartners", propss?.landId)}
          </h2>
          <div className="row offset-md-1">
            {partnerIMG?.map((e) => {
              return (
                <>
                  <div className=" partneretIMG  " id="Partners">
                    <div className="col-4 ">
                      <img
                        src={`${process.env.REACT_APP_UPLOADS}` + e.pic_url}
                        alt="Terres"
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Partnert);
