import React, { useState, useEffect } from "react";
import "./SingleCase.scss";
import axios from "../../axios";
import { useParams } from "react-router";
import Scroll from "./scroll";
import { connect } from "react-redux";

const SingleCase = (props) => {
  const [data, setdata] = useState([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [reportCase, setReportCase] = useState([]);
  const [singleCase, setSingleCase] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  const { id } = useParams();

  const getData = () => {
    axios
      .get("/web/case_discussion/case_id/" + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setdata(response.data);
        setLoading(false);
      })

      .catch((err) => console.log("error", err));

    axios
      .get(
        "/web/report_cases/findByUser/" + window.localStorage.getItem("id"),
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        // console.log("dataaa", res?.data);
        setReportCase(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [dynamicData]);

  const myD = () => {
    reportCase?.map((el) => {
      if (el.id == id) {
        setSingleCase(el);
      } else return null;
    });
  };

  useEffect(() => {
    myD();
  }, [reportCase]);

  const handleText = (e) => {
    setComment(e.target.value);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let replay = {
      case_id: id,
      content_al: comment,
      uuid: window.localStorage.getItem("id"),
    };

    let error = 0;

    if (comment == "") {
      setError("Please Write Something");
      error = 1;
    }

    if (error == 0) {
      axios
        .post("/mobile/case_discussion", replay, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setDynamicData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });

      setComment("");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <h3 className="title  text-center mt-4">
              {translateMe("reportType", props?.landId)}: {singleCase.cat_al}
            </h3>
            <h4 className="  text-center">{translateMe("caseName", props?.landId)}: {singleCase.case_al}</h4>
            <div className="chat overflow-auto  mt-5 mb-5 pe-5 pt-3">
              {data?.map((e) => {
                return (
                  <>
                    <div>
                      {e.uuid == window.localStorage.getItem("id") ? (
                        <div className="user">
                          <span> {e.content_al}</span>
                        </div>
                      ) : (
                        <div className="admin border">
                          <span> {e.content_al}</span>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="form-chat">
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="replaytext"
                    value={comment}
                    onChange={handleText}
                    placeholder=
                    {translateMe("typeYourMessage", props?.landId)}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-primary" type="submit">
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <p className="text-danger">{error}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleCase);
